.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.smoke {
  position: absolute;
  background: #f5f5f5;
  width: 10px;
  height: 20px;
  border-radius: 10px;
  animation: smoke 2s ease-in-out infinite;
}

@keyframes smoke {
  0% {
    transform: translateY(0) rotate(0);
    opacity: 0.8;
  }
  50% {
    transform: translateY(-50px) rotate(15deg);
    opacity: 0.4;
  }
  100% {
    transform: translateY(-100px) rotate(30deg);
    opacity: 0;
  }
}